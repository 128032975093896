/**
 * Layout for auth pages
 */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

import {
  CHRIS_SLIDEBOARD_IMG,
  ELIOT_JUMP_IMG,
  GIRL_SITTING_IMG,
  JARRETT_CLIMB_IMG,
  JKP_GREY_LOGO_IMG,
} from '../../const';
import { ROUTES } from '../../routes/const';
import Subscribe from '../Subscribe/Subscribe';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    '& img': {
      width: '100%',
      objectFit: 'cover',
      height: '100%',
      maxHeight: '1008px',
    },
  },
  image: { width: '100%', maxWidth: 480, marginBottom: theme.spacing(4) },
  footerItem: {
    display: 'flex',
    flexDirection: 'column',
    height: 90,
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));
const FooterContainer: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Box my={5}>
            <Subscribe />
          </Box>
        </Grid>

        <Hidden xsDown>
          <Grid item sm={3} xs={12}>
            <img alt="sitting" src={GIRL_SITTING_IMG} style={{ filter: 'grayscale(100%)' }} />
          </Grid>
        </Hidden>
        <Grid item sm={3} xs={12}>
          <img alt="jarrett climb" src={JARRETT_CLIMB_IMG} />
        </Grid>
        <Hidden xsDown>
          <Grid item sm={3} xs={12}>
            <img
              alt="slideboard"
              src={CHRIS_SLIDEBOARD_IMG}
              style={{ filter: 'grayscale(100%)' }}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <img alt="eliot jump" src={ELIOT_JUMP_IMG} />
          </Grid>
        </Hidden>
      </Grid>
      <Box px={{ xs: 2, sm: 0 }} py={{ xs: 2, sm: 8 }}>
        <Grid container spacing={2}>
          <Grid item sm={3} style={{ display: 'flex' }} xs={12}>
            <img alt="JKP" src={JKP_GREY_LOGO_IMG} style={{ height: 'auto', margin: 'auto' }} />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Box display="flex" flexDirection="column">
              <Typography color="secondary" variant="h3">
                NAVIGATE
              </Typography>
              <Box className={classes.footerItem}>
                <a href={`${ROUTES.Home}#about`}>
                  <Typography>ABOUT</Typography>
                </a>
                <Link to={ROUTES.Program}>
                  <Typography>THE PROGRAM</Typography>
                </Link>
                <Link to={ROUTES.Blog}>
                  <Typography>BLOG</Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Box display="flex" flexDirection="column">
              <Typography color="secondary" variant="h3">
                CONTACT
              </Typography>
              <Box className={classes.footerItem}>
                <a
                  href="mailto:jarrett@jkp.fitness"
                  rel="noopener noreferrer"
                  style={{ display: 'flex', margin: '0 auto 0 0' }}
                  target="_blank"
                >
                  <Typography>jarrett@jkp.fitness</Typography>
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Box display="flex" flexDirection="column">
              <Typography color="secondary" variant="h3">
                STAY CONNECTED
              </Typography>{' '}
              <Box className={classes.footerItem}>
                <a
                  href="https://www.instagram.com/jarrettkolichperformance"
                  rel="noopener noreferrer"
                  style={{ display: 'flex', margin: 'auto auto auto 0' }}
                  target="_blank"
                >
                  <Box mr={0.5}>
                    <InstagramIcon />
                  </Box>
                  <Typography>@jarrettkolichperformance</Typography>
                </a>
                <a
                  href="https://www.facebook.com/jarrettkolichperformance"
                  rel="noopener noreferrer"
                  style={{ display: 'flex', margin: 'auto auto auto 0' }}
                  target="_blank"
                >
                  <Box mr={0.5}>
                    <FacebookIcon />
                  </Box>
                  <Typography>@jarrettkolichperformance</Typography>
                </a>
                <Typography>#jarrettkolichperformance</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default FooterContainer;
