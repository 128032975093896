// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const Subscribe: React.FC = () => (
  <Box alignItems="center" display="flex" flexDirection="column">
    <Typography gutterBottom align="center" component="div" variant="h3">
      Subscribe to get insights on training and performance.
    </Typography>
    <Box mt={1.5}>
      <a href="https://eepurl.com/hPRnIL" rel="noopener noreferrer" target="_blank">
        <Button size="large" variant="contained">
          Subscribe
        </Button>
      </a>
    </Box>
  </Box>
);

export default Subscribe;
