/**
 * Home Layout
 */
import { FC, ReactNode } from 'react';
import Carousel from 'react-material-ui-carousel';
// @ts-ignore
// import Plx from 'react-plx';
import { useNavigate } from 'react-router-dom';
import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import TitleText from '../../components/UI/TitleText';

import {
  BERNARD_WIN_IMG,
  CHAD_WIN_IMG,
  ELIOT_WHIP_IMG,
  FITNESS_ICON_IMG,
  GOALS_ICON_IMG,
  HEALTH_ICON_IMG,
  JARRETT_HEADSHOT_IMG,
  LUCA_PODIUM_IMG,
  SCIENCE_ICON_IMG,
} from '../../const';
import { ROUTES } from '../../routes/const';
import { Colors } from '../../themes/colors';

const useStyles = makeStyles((theme: Theme) => ({
  offering: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& img': {
      width: '100%',
      maxWidth: '135px',
      marginBottom: theme.spacing(5),
    },
  },
  textShadow: {
    textShadow: '0px 7px 24px #00141b;',
  },
  testimonial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(0, 7.5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1),
    },
  },
  hideButtonsOnSmallScreen: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  testimonialImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'top',
  },
  testimonialOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: Colors.Blue,
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    backgroundBlendMode: 'multiply',
    opacity: 0.73,
  },
  headshotImage: {
    width: '100%',
    objectFit: 'cover',
    height: '100%',
    maxHeight: '1008px',
  },
  carousel: {
    width: '100%',
  },
}));

// const parallaxDataOffering = [
//   {
//     start: 0,
//     end: 700,
//     properties: [
//       {
//         startValue: 0.8,
//         endValue: 1,
//         property: 'opacity',
//       },
//     ],
//   },
// ];
// const parallaxDataJarrettSprint = [
//   {
//     start: 470,
//     end: 1500,
//     properties: [
//       {
//         startValue: 0,
//         endValue: 1,
//         property: 'opacity',
//       },
//       {
//         startValue: 140,
//         endValue: 0,
//         unitString: '%',
//         property: 'translateY',
//       },
//     ],
//   },
// ];
// const parallaxDataAbout = [
//   {
//     start: 100,
//     end: 3500,
//     properties: [
//       {
//         startValue: 0.9,
//         endValue: 1,
//         property: 'opacity',
//       },
//       {
//         startValue: 50,
//         endValue: 0,
//         unitString: '%',
//         property: 'translateX',
//       },
//     ],
//   },
// ];

const HomeContainer: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <Container maxWidth="md">
        <Box>
          <TitleText yellow>THE ATHLETE PROGRAM</TitleText>
          <Typography gutterBottom color="secondary" variant="h3">
            WHAT WE OFFER
          </Typography>
          <Typography color="secondary">
            Jarrett Kolich’s online Athlete Training Program brings education, expertise and
            Jarrett’s personable coaching methods to your fingertips, helping you to reach fitness,
            competition and performance goals. Our number one goal with this program is to increase
            athletic performance by fine tuning movement patterns to: control athletic asymmetries,
            increase power and reduce susceptibility to injury.
          </Typography>
          <Box mt={10} my={7}>
            <Grid container spacing={2}>
              <Offering
                description="Target your anaerobic and aerobic capacity more efficiently to improve endurance and recovery."
                image={FITNESS_ICON_IMG}
                title="FITNESS"
              />
              <Offering
                description="Improving more than just your fitness, with Jarrett’s guidance get set to improve your overall health and competitive well-being."
                image={HEALTH_ICON_IMG}
                title="HEALTH"
              />
              <Offering
                description="Jarrett’s degree in Kinesiology, certifications in Postural Restoration and as a Strength & Conditioning Specialist guarantee this training program is based on sports science."
                image={SCIENCE_ICON_IMG}
                title="SCIENCE"
              />
              <Offering
                description="With first hand experience, Jarrett has focused aspects of the program on getting you to the top of your game, with results you can celebrate."
                image={GOALS_ICON_IMG}
                title="RESULTS"
              />
            </Grid>
          </Box>
          <Box my={7}>
            <Typography align="center" color="textPrimary" variant="h3">
              WE’VE CREATED AN ONLINE ATHLETE PROGRAM EXPERIENCE THAT IS PERSONALIZED TO YOU
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" my={7}>
            <Button onClick={(): void => navigate(ROUTES.Program)} size="large" variant="contained">
              LEARN MORE
            </Button>
          </Box>
        </Box>
      </Container>

      <Box display="flex" width="100%">
        <Carousel
          autoPlay
          navButtonsAlwaysVisible
          stopAutoPlayOnHover
          className={classes.carousel}
          indicatorContainerProps={{
            className: '',
            style: { position: 'absolute', marginBottom: '8px', bottom: 0 },
          }}
          navButtonsProps={{
            className: classes.hideButtonsOnSmallScreen,
            style: { backgroundColor: Colors.Yellow, color: Colors.Blue },
          }}
        >
          <Testimonial
            description={
              <>
                "Since I've started training with Jarrett, I've never enjoyed training more! I feel
                like the types of training and the specific exercises we do really focus on making
                my body work the right way before we focus on building strength or fitness. This
                allows me to enjoy my riding and training a whole lot more and be pain free."
              </>
            }
            image={LUCA_PODIUM_IMG}
            name="LUCA SHAW"
            title="WORLD CUP DOWNHILL MOUNTAIN BIKE RACER"
          />
          <Testimonial
            description={
              <>
                “In the first few weeks I learned so much about how the body works. Jarrett has
                given me so many actionable things to take into my riding and racing!”
              </>
            }
            image={BERNARD_WIN_IMG}
            name="BERNARD KERR"
            title="3-TIME RED BULL HARDLINE WINNER"
          />
          <Testimonial
            description={
              <>
                “Thanks for sharing your knowledge and training programs, but most of all going in
                the pain cave with me. A great program is everything and you make the hurt locker a
                fun challenge!”
              </>
            }
            image={CHAD_WIN_IMG}
            name="CHAD REED"
            title="MULTI-TIME AMA SUPERCROSS AND MOTOCROSS CHAMPION"
          />
          <Testimonial
            description={
              <>
                “Not only has Jarrett been an incredible trainer over the years, but he's also been
                a great friend. Believing in me as we embarked on the journey together to go from my
                pro debut to becoming a top-ten rider in the sport of World Cup Downhill Mountain
                Biking. As an athlete, you want someone who is going to work as hard as you do.
                Jarrett has always been that person for me. <br />
                You couldn't pay me enough to train with somebody else!”
              </>
            }
            image={ELIOT_WHIP_IMG}
            name="ELIOT JACKSON"
            title="FORMER WORLD CUP DOWNHILL MTB/RED BULL TV HOST"
          />
        </Carousel>
      </Box>

      {/* <Box> */}
      {/*  <Plx parallaxData={parallaxDataJarrettSprint}> */}
      {/*    <img alt="jarrett sprint" src={JARRETT_SPRINT_IMG} style={{ width: '100%' }} /> */}
      {/*  </Plx> */}
      {/* </Box> */}

      <Box id="about" position="relative">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <img
              alt="jarrett headshot"
              className={classes.headshotImage}
              src={JARRETT_HEADSHOT_IMG}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box bgcolor={Colors.White} height="100%" p={{ xs: 2, sm: 7 }}>
              {/* <Plx parallaxData={parallaxDataAbout}> */}
              <Box>
                <TitleText color="primary">ABOUT JARRETT</TitleText>
                <Typography color="primary" variant="h3">
                  THE TRAINER
                </Typography>
                <Box mt={3}>
                  <Typography color="primary">
                    With over 15 years of experience in Personal Training, Sports Science and
                    Athlete Coaching, Jarrett Kolich brings a wealth of knowledge, experience and
                    result driven discipline to his Athlete Training Program.
                    <br />
                    <br />
                    Jarrett’s own background in BMX Racing and competing at an International Level,
                    means he has first hand experience performing among some of the best athletes in
                    the world. After retiring from BMX he moved his athletic passion to Personal
                    Training backed by a degree in Kinesiology, from California State University,
                    Fullerton.
                    <br />
                    <br />
                    As a trainer you work with pro-active clientele, in school Jarrett worked as a
                    PT aide and dealt with patients who were on the flipside of that coin. “Trainers
                    work on the preventative side of the training table, meaning through good
                    training practices, I’d be able to help people avoid injury and improve physical
                    performance.” That differentiation struck a deep chord in Jarrett because of the
                    multiple injuries/surgeries he’d sustained and trained himself back to race at
                    the top level.
                    <br />
                    <br />
                    His true passion lies in helping <i>athletes</i> get every ounce of performance
                    out of their bodies that they can; teaching kids, gawky and raw, how to move
                    well; middle agers who lose mobility, strength, cardio fitness get back to
                    skiing, running, cycling.
                    <br />
                    <br />
                    “Nothing makes me feel better than Training. This is my purpose.“
                  </Typography>
                </Box>
              </Box>
              {/* </Plx> */}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box display="flex">
        <Box m="auto" mb={5} mt={10} px={2}>
          <TitleText align="center">GET THE RESULTS YOU CAN CELEBRATE!</TitleText>
        </Box>
      </Box>
    </>
  );
};

interface TestimonialProps {
  description: ReactNode;
  image: string;
  title: string;
  name: string;
}
const Testimonial: FC<TestimonialProps> = ({ description, image, name, title }) => {
  const classes = useStyles();
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="80vh"
      position="relative"
    >
      <img alt="testimonial" className={classes.testimonialImage} src={image} />
      <Box className={classes.testimonialOverlay} style={{ backgroundImage: `url(${image})` }} />
      <Box className={classes.testimonial}>
        <Typography
          gutterBottom
          align="center"
          className={classes.textShadow}
          color="secondary"
          variant="h2"
        >
          {name}
        </Typography>
        <Typography
          gutterBottom
          align="center"
          className={classes.textShadow}
          color="secondary"
          variant="h3"
        >
          {title}
        </Typography>
        <Box mt={4}>
          <Typography align="center" className={classes.textShadow} color="secondary" variant="h6">
            <i>{description}</i>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface OfferingProps {
  description: string;
  image: string;
  title: string;
}
const Offering: FC<OfferingProps> = ({ description, image, title }) => {
  const classes = useStyles();
  return (
    <Grid item lg={3} sm={6} xs={12}>
      {/* <Plx parallaxData={parallaxDataOffering}> */}
      <Box className={classes.offering}>
        <img alt="fitness" src={image} />
        <Typography gutterBottom color="secondary" variant="h3">
          {title}
        </Typography>
        <Typography align="center" color="secondary">
          {description}
        </Typography>
      </Box>
      {/* </Plx> */}
    </Grid>
  );
};

export default HomeContainer;
